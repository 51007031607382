.navbar {
  background-color: #2d2d2d;
}
.navlink {
  color: white!important;
  text-transform: uppercase;
  font-size: 13px;
}
.logo {
  width: 120px;
}
.form-control {
  height: calc(1.5em + 2rem + 2px);
  border: 1px solid #b1b1b1;  
}

:root {
  --input-padding-x: 1rem;
  --input-padding-y: 1.1rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #b1b1b1;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown), .form-label-group input:focus {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label, .form-label-group input:focus ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* DEFAULT */

body {
  font-family: 'Roboto Condensed';
}

.azul {
  color: #00adee;
}

.bgAzul {
  background-color: #00adee;
}

.verde {
  color: #20B47A;
}

.bgVerde {
  background-color: #20B47A;
}

.cinza {
  color: #979696;
}

.colorWhite {
  color: white;
  text-transform: uppercase;
}

h5 {
  font-size: 1.0rem;
  font-weight: 700;
}

.card-shadow {
  box-shadow: 2px 2px 5px #ddd;
}

.unSelected {
  background-color: #f2f2f2!important;
  border: 1px solid #f2f2f2!important;
}

p.bold {
  font-weight: 700;
}

p.grande {
  font-size: 1.0rem;
}

p.normal {
  font-size: 0.9rem;
}

p.pequena {
  font-size: 0.8rem;
}

.w100 {
  width: 100%;
}

.bottomRight {
  position: absolute;
  bottom: 8px;
  right: 15px;
}

.card {
  min-height: 80px; 
}

button.btnBlockGrande {
  font-size: 1.0rem;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
}

.btnAzulNB {
  margin-top: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
  border: none;
  width: 100%;
  max-width: 500px;
}

/* LISTA PEDIDO */


.card p {
  margin-bottom: 0px;
  text-transform: capitalize;
}
.cardbody-item {
  padding: 5px;
}
.cardbody-item > .row {
  margin: 5px;
}
.img-item {
  width: 20%;
  min-height: 70px;
}
.detalhe-item {
  width: 80%;
  font-size: 15px;
  font-weight: 500;
}
.detalhe-item p {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* LISTA FORMAS DE PAGAMENTO */

.cardFormaPag {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.cardFormaPag > div {
  width: 105px;
  padding-left: 20px;
}
.cardFormaPag > div > img {
  /* width: 65px; */
  height: 40px;
}

/* LISTA CARTÃO */

.cardCartao {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 78px;
  background-color: white;
}
.cardCartao > div {
  width: 105px;
  padding-left: 15px;
  padding-right: 5px;
}
.cardCartao > div > img {
  width: 65px;
  /* height: 40px; */
}
.cardCartao > p:first-of-type {
  width: 110px;
  padding-right: 20px;
  padding-left: 0px;
}
.cardCartao.opened {
  border-bottom: none;
}
.cardCartaoBody .form-control {
  height: 40px;  
}
.cardCartaoBody .form-group {
  margin-bottom: 0px;
}

.cardAddCartao {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.cardAddCartao > img {
  height: 40px;
}
.cardAddCartao > p:first-of-type {
  padding-right: 20px;
  padding-left: 20px;
}
.cardAddModal {
  padding-left: 0px;
  padding-top: 0px;
}
.cardAddModal p {
  margin-top: 10px;
}

.cardResumo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardResumo > div {
  text-align: right;
}

/* NAO CARTAO */
.cardBoletoDebito > div {
  display: flex;
  align-items: center;
}
.cardBoletoDebito > div > img {
  margin-right: 20px;
}

.navBrandCustom {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lockSvg {
  width: 20px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(234deg) brightness(104%) contrast(104%);
}

.diviLogo {
  display: block;
  width: 1px;
  height: 30px;
  background-color: white;
  margin-left: 15px;
  margin-right: 15px;
}

.textoLogo {
  color: white;
  font-weight: 600;
  font-family: 'Roboto Condensed';
  font-size: 1.0rem;
  margin-left: 10px;
}